import { SetupContext } from "@vue/composition-api";

export default function useSelectItems({ root }: Partial<SetupContext>) {
  const eventStatusItems = [
    {
      name: root?.$t("layout.event.statusItems.all"),
      value: "",
    },
    {
      name: root?.$t("layout.event.statusItems.incoming"),
      value: "incoming",
    },
    {
      name: root?.$t("layout.event.statusItems.inProgress"),
      value: "in_progress",
    },
    {
      name: root?.$t("layout.event.statusItems.complete"),
      value: "complete",
    },
  ];

  const eventStatusItemsLite = [
    {
      name: root?.$t("layout.event.statusItems.incoming"),
      value: "incoming",
    },
    {
      name: root?.$t("layout.event.statusItems.inProgress"),
      value: "in_progress",
    },
    {
      name: root?.$t("layout.event.statusItems.complete"),
      value: "complete",
    },
  ];

  const eventTypeItems = [
    {
      name: root?.$t("layout.event.typeItems.all"),
      value: "",
    },
    {
      name: root?.$t("layout.event.typeItems.conference"),
      value: "conference",
    },
    {
      name: root?.$t("layout.event.typeItems.online"),
      value: "online",
    },
    {
      name: root?.$t("layout.event.typeItems.course"),
      value: "course",
    },
    {
      name: root?.$t("layout.event.typeItems.reception"),
      value: "reception",
    },
    {
      name: root?.$t("layout.event.typeItems.siemens"),
      value: "siemens",
    },
  ];

  const eventTypeItemsLite = [
    {
      name: root?.$t("layout.event.typeItems.conference"),
      value: "conference",
    },
    {
      name: root?.$t("layout.event.typeItems.online"),
      value: "online",
    },
    {
      name: root?.$t("layout.event.typeItems.course"),
      value: "course",
    },
    {
      name: root?.$t("layout.event.typeItems.reception"),
      value: "reception",
    },
    {
      name: root?.$t("layout.event.typeItems.siemens"),
      value: "siemens",
    },
  ];

  const participantStatusItems = [
    {
      name: root?.$t("layout.participant.statusItems.participant"),
      value: "participant",
    },
    {
      name: root?.$t("layout.participant.statusItems.reserve"),
      value: "reserve",
    },
    {
      name: root?.$t("layout.participant.statusItems.resigned"),
      value: "resigned",
    },
    {
      name: root?.$t("layout.participant.statusItems.removed"),
      value: "removed",
    },
  ];

  const participantStatusItemsSmall = [
    {
      name: root?.$t("layout.participant.statusItems.participant"),
      value: "participant",
    },
    {
      name: root?.$t("layout.participant.statusItems.reserve"),
      value: "reserve",
    },
    {
      name: root?.$t("layout.participant.statusItems.resigned"),
      value: "resigned",
    },
  ];

  const languageItemsCurrency = [
    {
      value: "polish",
      name: root?.$t("layout.languageVersion.currency.polish"),
    },
    {
      value: "english",
      name: root?.$t("layout.languageVersion.currency.english"),
    },
  ];

  const languageItems = [
    {
      value: "polish",
      name: root?.$t("layout.languageVersion.basic.polish"),
    },
    {
      value: "english",
      name: root?.$t("layout.languageVersion.basic.english"),
    },
  ];

  const registrationTypeItems = [
    {
      name: root?.$t("layout.registrationTypeItems.registration"),
      value: "registration",
    },
    {
      name: root?.$t("layout.registrationTypeItems.afterPayment"),
      value: "afterPayment",
    },
    {
      name: root?.$t("layout.registrationTypeItems.autoMessage"),
      value: "auto",
    },
    {
      name: root?.$t("layout.registrationTypeItems.removeMessage"),
      value: "remove",
    },
    {
      name: root?.$t("layout.registrationTypeItems.other"),
      value: "other",
    },
  ];

  const salutationItems = [
    {
      value: "Pan",
      language: "pl",
    },
    {
      value: "Pani",
      language: "pl",
    },
    {
      value: "Mr.",
      language: "en",
    },
    {
      value: "Ms.",
      language: "en",
    },
  ];

  const currencyItems = [
    {
      name: "PLN",
    },
    {
      name: "EUR",
    },
  ];

  const shortLanguageItems = [
    {
      value: "pl",
      name: root?.$tc("languages.pl"),
    },
    {
      value: "en",
      name: root?.$tc("languages.en"),
    },
  ];

  return {
    eventStatusItems,
    eventStatusItemsLite,
    eventTypeItems,
    eventTypeItemsLite,
    participantStatusItems,
    participantStatusItemsSmall,
    languageItemsCurrency,
    languageItems,
    registrationTypeItems,
    salutationItems,
    currencyItems,
    shortLanguageItems,
  };
}
