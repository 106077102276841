














































import useSelectItems from "@/use/selectItems";
import {
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";

export default defineComponent({
  props: {
    value: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  setup(props, { root }) {
    const { participantStatusItems } = useSelectItems({ root });
    const state = reactive({
      groups: [],
      loading: false,
      tempGroup: "",
    });

    const fetchGroups = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`/event/${root.$route.params.id}/participant/group/select`)
        .then(({ data: { groups } }) => {
          state.groups = groups;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.groups = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loading = false;
        });
    };

    onMounted(fetchGroups);

    watch(
      () => state.tempGroup,
      () => {
        props.value.participantGroup.values = [state.tempGroup];
      }
    );

    return { participantStatusItems, state };
  },
});
